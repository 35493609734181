$transitionSpeed: .4s;

.matchBanner {
  display: flex;
  justify-content: space-around;
  padding: 60px 100px;
  align-items: center;
  border-radius: 12px;
  background-repeat: repeat, no-repeat;
  background-size: auto, 100% 100%;
  background-position: 50%;
  position: relative;
  background-color: var(--clubBg1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  color: var(--clubNavigation2);
  height: 280px;
  text-align: center;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .league {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .leagueIcon {
        margin: 0 6px;
        transition: width ease-in $transitionSpeed, height ease-in $transitionSpeed;
      }

      .leagueTitle {
        text-transform: uppercase;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        transition: font-size ease-in $transitionSpeed;
      }
    }

    .startTime {
      transition: margin-top ease-in $transitionSpeed;
    }

    .stadiumContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .stadiumTitle {
        text-transform: uppercase;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        margin: 0 6px;
        transition: font-size ease-in $transitionSpeed;
      }

      .stadiumIcon {
        transition: width ease-in $transitionSpeed, height ease-in $transitionSpeed;
      }
    }

    h3 {
      margin: 16px 0 16px;
    }
  }
}

// FIXME: это надо отсюда выпилить и так больше никогда не делать! Для этого есть настройки через ENV. 
//        Увижу - прокляну
.fanzilla {
  background-image: url('./assets/bg.png'), url('./assets/shadow-blur.webp');
}

.shinnik {
  background-image: url('./assets/shinnik/bg_shinnik_blue.svg'), url('./assets/shinnik/ellipse-blur.svg');
}

.chernomorochka {
  background-image: url('./assets/bg.png'), url('./assets/chernomorochka/ellipse-blur.svg');
}
// по сюда

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  overflow: hidden;
}

.logoSize18 {
  height: 18px;
  width: 18px;
  transition: width ease-in $transitionSpeed, height ease-in $transitionSpeed;
}

.leagueBanner {
  height: auto;
  width: 100%;
  border-radius: 12px;
}

.teamContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 30%;

  .teamLogo {
    position: relative;
  }

  .teamLogoImg {
    width: 90px;
    height: 90px;
    position: absolute;
    z-index: 3;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width ease-in $transitionSpeed, height ease-in $transitionSpeed;
  }

  .teamLogoBackground {
    position: relative;
    z-index: 2;
    width: 98px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    overflow: hidden;
    transition: width ease-in $transitionSpeed, height ease-in $transitionSpeed;

    & > div {
      // width: 90%;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  h3 {
    margin: 22px 0 0;
  }

  p {
    margin: 7px 0;
    opacity: 0.5;
  }

  .teamTitle, .teamLocation {
    transition: font-size ease-in $transitionSpeed;
    text-align: center;
  }
}

.bannerBtn {
  height: 32px;
  padding: 6px 16px;
  background-color: var(--clubAccent);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0.9;
  margin: 24px 0 0;
}

@media only screen and (max-width: 920px) {
  .matchBanner  {
    padding: 20px 30px;
  }
}

@media only screen and (max-width: 760px) {
  .matchBanner  {
    padding: 20px 30px;
    flex-wrap: wrap;
    height: auto;
    cursor: pointer;

    .info {
      order: 1;
      width: 100%;
    }

    .teamContainer {
      order: 2;
    }
  }
}

@media screen and (min-width: 761px) and (max-height: 1080px) {
  .event {

    .matchBanner {
      height: 160px;
      padding: 0 60px;
      gap: 24px;
    }

    .teamContainer {
      flex-direction: row;
      justify-content: center;
      gap: 14px;
    }

    .teamLogoBackground {
      min-height: 60px;
      max-height: 60px;
      min-width: 60px;
      max-width: 60px;
      width: 60px;
      height: 60px;
    }

    .teamLogoImg {
      width: 56px;
      height: 56px;
    }

    h3 {
      margin: 7px 0 0;
    }
  }
}

@media only screen and (max-width: 760px) {
  .matchBanner  {
    padding: 20px 10px;

    .info {
      h3 {
        margin: 10px 0;
        font-size: 17px;
      }
    }

    .teamLogoImg {
      width: 45px;
      height: 45px;
    }

    .teamLogoHide {
      width: 0;
      height: 0;
    }

    .teamContainer {
      h3 {
        margin-top: 15px;
        font-size: 17px;
      }

      p {
        font-size: 12px;
      }

      .teamLogoBackground {
        width: 50px;
        height: 50px;
      }
    }
  }

  .onTopMatchBanner {
    .textHide {
      font-size: 0 !important;
    }
    .teamTitleSmall {
      font-size: 14px;
    }
    .marginTop22 {
      margin-top: 22px !important;
      margin-bottom: 0 !important;
    }
    .logoHide, .stadiumIconHide, .teamLogoBackground {
      height: 0 !important;
      width: 0 !important;
      min-height: 0 !important;
      min-width: 0 !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .matchBanner  {

    .info {
      h3 {
        font-size: 15px;
      }
    }

    .teamContainer {
      h3 {
        margin-top: 12px;
        font-size: 15px;
      }

      p {
        font-size: 10px;
      }
    }
  }
}
