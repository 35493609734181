.wrapper {
  display: flex;
  flex-direction: column;
}


.title {
  margin: 12px 0;
}

.grid {
  display: grid;
}

// .grid > div:nth-child(2n + 1):last-child {
//    grid-column: 1 / -1;
// }