.ShoppingCart {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: var(--systemWhite);
  border: 1px solid rgba(50, 50, 50, 0.25);
  border-radius: 12px;

  .cart {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: 1fr;
    padding: 24px 0 20px;
    height: 100%;
  }
  
  @media screen and (max-width: 720px) {
    .cart {
      grid-template-columns: 1fr;
  
      aside {
        border-left: none;
  
        h3 {
          margin: 6px 0;
        }
      }
  
      main, aside {
        padding: 0 12px;
      }
    }
  }
}

