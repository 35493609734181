@import '../../../node_modules/normalize.css/normalize.css';

:root {
  --clubBg1: var(--fan-theme_clubBg1, rgba(104,60,147,1));
  --clubBg2: var(--fan-theme_clubBg2, rgba(236,233,224,1));
  --clubBg3: var(--fan-theme_clubBg3, rgba(55,24,85,1));
  --clubNavigation1: var(--fan-theme_clubNavigation1, rgba(51,51,51,1));
  --clubNavigation2: var(--fan-theme_clubNavigation2, rgba(255,255,255,1));
  --clubAccent: var(--fan-theme_clubAccent, rgba(129,59,195,1));
  --clubPrimary: var(--fan-theme_clubPrimary, rgba(129,59,195,1));
  --clubPrimaryLight: var(--fan-theme_clubPrimaryLight, rgba(172,110,231,1));
  --systemBlack: var(--system_Black, rgba(50,50,50,1));
  --systemBlack05: var(--system_Black05, rgba(50, 50, 50, 0.05));
  --systemBlack10: var(--system_Black10, rgba(50, 50, 50, 0.1));
  --systemBlack25: var(--system_Black25, rgba(50, 50, 50, 0.25));
  --systemBlack50: var(--system_Black50, rgba(50, 50, 50, 0.5));
  --systemGreen: var(--system_Green, rgba(39,174,96,1));
  --systemOrange: var(--system_Orange, rgba(255,149,0,1));
  --systemPureBlack: var(--system_PureBlack, rgba(0,0,0,1));
  --systemRed: var(--system_Red, rgba(235,87,87,1));
  --systemWhite: var(--system_White, rgba(255,255,255,1));
  --system_White50: var(--system_White50, rgba(255,255,255,0.5));
  --systemYellow: var(--system_Yellow, rgba(242,201,76,1));
  --clubLogo: var(
    --clubLogoOverride,
    url('../assets/images/defaultHome.svg')
  );
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

/* Должен быть обязательно #root, для работы хука useLockedBody */
html, #root {
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: 'TT Hoves', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  position: relative;
  background-color: var(--clubBg2);
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

a {
  text-decoration: none;
}

ol,
ul {
  margin-block: 0;
  margin-inline: 0;
  padding: 0;
  list-style: none;
  line-height: 1.5em;
}

button {
  outline: none;
}
