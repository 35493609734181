.customCheckbox {
  margin: 12px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  color: var(--clubNavigation1);

  .inputCheckbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .inputCheckbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  .inputCheckbox+label::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1.3px solid rgba(50, 50, 50, 0.5);
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }

  .inputCheckbox:checked+label::before {
    border-color: var(--clubPrimary);
    background-color: var(--clubPrimary);
    background-image: (url('./../../../../assets/icons/checkbox.svg'));
    background-position: center;
    background-size: auto;
  }
}
