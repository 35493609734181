.eventContainer {
  background: var(--systemWhite);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  position: relative;
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;

  .eventHomeTeam {
    font-weight: 700;
    font-size: 17px;
    letter-spacing: -0.43px;
    color: var(--systemBlack);
    margin-bottom: 0;
    line-height: 110%;
  }

  .eventGuestTeam {
    font-weight: 700;
    font-size: 17px;
    line-height: 110%;
    letter-spacing: -0.43px;
    color: var(--systemBlack);
    opacity: 0.5;
  }
}

.eventArrow {
  position: absolute;
  right: 6.25px;
  padding: 6px;
  bottom: 6.55px;
  cursor: pointer;
  svg {
    path {
      fill: var(--systemBlack25);
    }
  }
}

.eventLogo {
  position: relative;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;

  & > div {
    // width: 90%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.eventInfo {
  margin-left: 17px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  color: var(--systemBlack);

  .eventLeague {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;

    span {
      margin-left: 6px;
    }
  }

  .eventDate {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;
    svg {
      margin-right: 6px;
    }

    span {
      margin-right: 4px;
      color: var(--clubPrimary);
    }
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leagueIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logoSize16 {
  height: 16px;
  width: 16px;
}

.logoSize70 {
  height: 70px;
  width: 70px;
}
