.map {
  display: flex;
  height: 400px;
  // flex-direction: column;
  // overflow: hidden;
  width: 100%;
}

.mapContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: var(--systemWhite);
  border: 1px solid rgba(50, 50, 50, 0.25);
  border-radius: 12px;
}

#mapmobile {
  height: 100vh;
}