
.wrap {
  background: var(--systemWhite);
  opacity: 0.9;
  border: 1px solid rgba(50, 50, 50, 0.25);
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  height: 44px;

  svg {
    height: 100%;
  }

  input.error {
    color: var(--systemRed);
  }

  label {
    order: 1;
  }

  input {
    border: none;
    background: transparent;
    outline: none;
    color: var(--systemBlack);
    flex: 1;
    order: 2;
    width: 100%;
    padding-right: 5px;
  }

  button {
    order: 3;
  }
}

label.error {
  margin-top: 4px;
  font-size: 14px;
  line-height: 110%;
  color: var(--systemRed);
}

.textButton {
  outline: none;
  border: none;
  background: transparent;
  padding: 0;

  cursor: pointer;
  color: var(--clubPrimary);

  &:disabled {
    color: var(--systemBlack50);
  }
}

.editor {
  margin: 0 10px;
}

.PromocodeInput {
  min-height: 44px;
}
