.ticket {
  border: 1px solid rgba(50, 50, 50, 0.25);
  border-radius: 8px;
  min-width: 290px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  line-height: 1.15;

  .place {
    margin-left: 8px;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    .placeName {
      padding: 6px 0;
      p {
        margin: 0;
        color: var(--systemBlack);
        opacity: 0.5;
        font-size: 10px;
        line-height: 120%;
        letter-spacing: 0.12px;
      }
    }
  }

  h2 {
    font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 0 2px;
    font-size: 31px;
  }

  span {
    color: var(--clubPrimary)
  }
}

.isMobileTicket {
  color: var(--systemBlack);
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
}

@media screen and (max-width: 390px) {
  .isMobileTicket {
    font-size: 11px;
  }
}
