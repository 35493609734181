.menuOverlay {
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--clubPrimary);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &_visible {
    visibility: visible;
    opacity: 1;
  }
}

.mobileMenyBody {
  position: absolute;
  z-index: 10;
  // background-color: var(--clubPrimary);
  background-color: transition;
  border-radius: 10px;
  padding: 16px;
  top: 80px;
  left: 0;
  right: 0;
  transform: translate(0, -5%);
  opacity: 0;
  visibility: hidden;
  // transition: all 0.3s ease-out;
  overflow: hidden;

  &_visible {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 5%);
  }

  ul {
    display: grid;

    li a {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      padding: 10px;
      color: var(--systemWhite);
      border-radius: 10px;
      cursor: pointer;

      svg {
        fill: var(--systemWhite);
      }

    &.active {
      border: 1px solid var(--systemBlack50);
      transition: 0.3s;

      svg {
        fill: var(--systemWhite);
      }
    }
  }
}
}
