header.headerStyle {
  align-items: center;
  border-bottom: 1px solid var(--systemBlack25);
  padding: 14px;
}

.headerWithTitle {
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  span {
    &:first-child {
      justify-self: start;
      font-size: 14px;
    }
  
    &:last-child {
      justify-self: end;
    }
  }

  h2 {
    justify-self: center;
    margin: 0;
  }
}

@media (max-width: 600px) {
  header.headerStyle {
    h2 {
      font-size: 18px;
    }
  }
}