.shippingOption {
  justify-content: space-between;
  display: grid;
  grid-template-columns: auto minmax(min(100%, 150px), auto ) 1fr;
  grid-template-areas: 'check title input';
  align-items: center;
  margin: 15px 0;

  div {
    &:first-child {
      grid-area: check;
    }

    &:last-child {
      grid-area: input;
    }
  }

  span {
    margin: 0 8px;
    white-space: nowrap;
    grid-area: title;
  }
}

.OptionsForSend {
  padding: 20px;
  button {
    margin: 0 auto;
  }
  p {
    margin: 0;
  }
}

@media (max-width: 920px) {
  .shippingOption {
    min-width: 380px;
  }

  .shippingOption {
    grid-template-columns: auto 1fr;
    grid-template-areas:  'check title'
                          'input input';
  }
}

@media (max-width: 600px) {
  .shippingOption, .successWrap {
    min-width: 265px;
  }

  .shippingOption {
    font-size: 14px;
  }
}