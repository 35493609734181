.backBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  border: none;
  color: inherit;
  background: var(--systemBlack25);
  color: var(--systemWhite);
  border-radius: 8px;
  width: 103px;
  padding: 4px 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}

@media only screen and (max-width: 520px) {
  .backBtn {
    width: 74px;

    span {
      font-size: 14px;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }
}