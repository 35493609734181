.event {
  display: grid;
  grid-template-rows: min-content auto;
}

.header {
  h3 {
    margin: 16px 0 0;
   span {
    opacity: 0.5;
   }
  }

  .subtitle {
    display: flex;
    align-items: center;
    
    p{
      font-size: 14px;
      line-height: 110%;
      color: var(--systemBlack);
      opacity: 0.5;
      margin-left: 5px;
    }
  }
}

.logoSize16 {
  height: 16px;
  width: 16px;
}

@media (max-width: 380px) {
  .header {
    .subtitle {
      p {
        font-size: 13px;
      }
    }
  }
}
