  .seasonPassContainer {
    background: var(--clubPrimary);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 16px;
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;

    .seasonPassLogo {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--systemBlack);
    }

    .seasonPassInfo {
      // width: 50%;
      // margin-left: 17px;
      // margin-top: 2px;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--systemWhite);

        .seasonPassTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 110%;
          letter-spacing: -0.26px;
          margin: 24px 0 6px;
        }

        .seasonPassSubtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 110%;
          letter-spacing: -0.08px;
        }
    }

    .seasonPassArrow {
      position: absolute;
      right: 6.25px;
      padding: 6px;
      bottom: 6.55px;
      cursor: pointer;
      svg {
        path{
          fill: var(--clubPrimaryLight);
        }
      }
    }

    .seasonDecoration {
      position: absolute;
      right:-7px;
      padding: 6px;
      top: -6px;

      svg {
        fill: var(--systemBlack);
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
  }
  
  .logoSize70 {
    height: 70px;
    width: 70px;
  }
