.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  
  h1 {
    margin: 0 0 10px;
  }
}

.emptyCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 12px;

  svg {
    max-width: 90%;
    height: auto;
  }

  h5 {
    margin: 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    margin: 8px 0 16px;
  }
}

@media only screen and (max-width: 768px) and (orientation:landscape){
  .wrapper {
    position: relative;
  }
}

@media screen and (max-width: 720px) {
  .wrapper {
    position: relative;
  }
}