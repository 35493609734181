.modalLoyalty{
  color: var(--systemWhite);
}

.overlay {
  display: block;
}

.infoSceen {
  max-width: 764px;

  header.infoHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--systemBlack25);
    padding: 27px 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
    }

    .infoCloseBtn {
      position: absolute;
      top: 14px;
      right: 14px;
      z-index: 5;
    }

    .infoHeaderBody {
      display: flex;
      flex-direction: column;
      text-align: start;
      color: var(--systemBlack);

      h1 {
        color: var(--systemWhite);
        margin: 0;
      }

      p {
        max-width: 420px;
        font-size: 16px;
        font-weight: 500;
        line-height: 110%;
        margin: 10px 0 0;
      }

      h3 {
        margin: 10px 0 0;
      }

      div {
        font-size: 18px;
      }

      span {
        font-weight: bold;
        font-size: 48px;
        line-height: 120%;
        color: #ffffff;
      }
    }
  }

  .infoMain {
    padding: 8px 32px 64px;
    color: var(--systemBlack);

    h2 {
      margin: 24px 0 0;
    }

    p {
      margin: 16px 0 0;
    }

    a {
      color: var(--clubPrimary);
      cursor: pointer;
    }
  }
}

@media (max-width: 840px) {
  .infoSceen {
    max-width: 764px;
  }
}

@media (max-width: 600px) {
  .infoSceen {
    header.infoHeader {
      padding: 27px 16px 24px;

      .infoHeaderBody {
        p {
          max-width: 316px;
          font-size: 14px;
        }
      }
    }
    .infoMain {
      padding: 0 16px 24px;

      p {
        font-size: 16px;
      }
    }
  }

}
