.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentDetails {
  color: var(--systemBlack);
  min-height: 125px;
}

.value {
  font-size: 14px;
  line-height: 110%;
  margin: 5px 0;
  white-space: nowrap;
  span {
    color: var(--systemOrange)
  }
}

.paymentsName {
  font-size: 14px;
  line-height: 110%;
  margin: 5px 0;
  span {
    color: var(--systemOrange)
  }
}

.sumTicketDetails {
  border-top: 2px solid rgba(50, 50, 50, 0.1);
  padding: 5px 0;
  margin: 5px 0;

}