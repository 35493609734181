.App {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: 11;
}

.shinnik {
  background-image: linear-gradient(#00000000, var(--clubBg2)), url('./assets/shinnik/bg_shinnik_grey.svg');
  background-repeat: repeat-x, repeat-x;
  background-size: auto 170px, auto 170px;
  background-position: top center;
}
