.itemsContainer {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li.mobileMenu {
      margin-right: 12px;
    }

    li.burger {
      z-index: 15;
    }

    li {
      margin-right: 8px;
      white-space: nowrap;
      cursor: pointer;

      button {
        background: transparent;
        border: none;
        outline: none;
      }

      .linkItems {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 9px;
        min-height: 48px;
        border-radius: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 110%;
        color: var(--clubNavigation1);

        svg {
          margin: 0 6px;
        }

        &.active {
          border: 1px solid var(--systemBlack25);
          transition: 0.3s;

          svg {
            fill: var(--clubPrimary);
          }
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

