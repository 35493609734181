.onboarding {
  background-color: var(--clubBg2);
  padding: 26px 0 26px 89px;
  height: 100%;
  display: flex;
  overflow: hidden;

  .content {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding-right: 118px;
  }

  .nav {
    display: flex;
    align-items: center;
    max-width: 315px;
  }

  .footer {
    a {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      color: var(--systemBlack50);

      &:hover {
        color: var(--systemBlack25);
      }
    }
  }

  .aside {
    width: 50%;
    img,
    video {
      height: calc(100% - 50px);
      border-radius: 10px 0 0 10px;
    }
  }
}

@media (max-width: 880px) {
  .onboarding {
    display: grid;
    justify-items: center;
    grid-template-rows: 40% 60%;

    overflow: auto;
    padding: 0;

    .content {
      height: 100%;
    }

    .main {
      width: calc(100% - 32px);
      height: 100%;
      padding-right: 0;
    }

    .nav {
      max-width: none;
      width: 100%;
      flex-direction: column-reverse;
      gap: 16px;
      margin-bottom: 16px;

      button {
        width: 100%;
        min-height: 52px;
      }
    }

    .aside {
      width: 100%;
      grid-row-start: 1;

      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
      img {
        object-fit: cover;
      }
      video {
        object-fit: contain;
      }
    }

    .footer {
      text-align: center;
      padding-bottom: 16px;
    }
  }
}

@media (max-height: 568px) {
  .content {
    height: auto !important;
  }
}