.wrapper {
  background-color: var(--systemWhite);
  border-radius: 12px;
  height: auto;

  .nonAuthWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .columns {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .leftColumn {
      order: 1;
      flex-basis: 100%;
    }

    .rightColumn {
      order: 2;
      flex-basis: 100%;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    border-top: solid 1px;
    border-color: rgba($color: #BDBDBD, $alpha: 0.3);
  }
}

.pageTitle {
  margin-top: 24px;
}

.addToCart {
  height: 40px;
  padding: 9.5px 16px 11px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  margin-left: auto;
}

.cartIcon {
  width: 24px;
  height: 20px;
  margin-right: 10px;
}

.columnTitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 10px;

  .text {
    font-size: 16px;
    font-weight: 700;
  }
}

.ticketRow {
  display: flex;
  margin-bottom: 12px;

  & > div {
    width: 100%;
  }

  :global(.place){
    width: 455px;
  }

  align-items: center;

  .checkbox {
    margin-right: 8px;
  }
}

@media screen and (min-width: 990px) {
  .wrapper {
    .columns {
      flex-wrap: nowrap;

    }
  }
  .leftColumn {
    order: 1;
    flex-basis: 200px;
    padding-right: 20px;
  }

  .rightColumn {
    order: 2;
    flex-basis: 200px;
    padding-left: 20px;
  }

  .addToCart {
    align-items: center;
    max-width: fit-content;
  }
}
