.leaflet-popup-content {
  margin: 8px;
}

.photoContainer {
  width: 100%;
  height: 139px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.photoFooter {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(50, 50, 50, 0.9);
  border-radius: 4px;
  padding: 4.5px 8px;
  &__item {
    display: flex;
    align-items: center;
    &__center {
      display: flex;
      align-items: center;
      margin: 0 8px;
    }
    &__value {
      font-weight: normal;
      font-size: 12px;
      color: #fff;
      span {
        font-weight: bold;
      }
    }
  }
}

#map {
  height: 512px;
  background: #f2f2f2;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  transition: height ease-in 0.4s;
}

@media (max-width: 768px) {
  #map {
    width: 100%;
    height: 538px;
    margin: 0 auto 0;
    padding-bottom: 50px;
  }
  // .tickets__wrapper{
  //   padding: 0 18px 0 16px;
  // }
}

.leaflet-bottom.leaflet-right {
  display: none;
}

#map svg {
  shape-rendering: optimizeSpeed !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  border-radius: 50% !important;
  border: solid 1px #d9d9d9 !important;
  font-weight: 100;
  line-height: -1px;
  font-size: 0 !important;
  position: relative;
}

.leaflet-control-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.leaflet-control-zoom-in {
  margin-bottom: 10px;
  background-image: url('./plus.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
}

.leaflet-control-zoom-out {
  background-image: url('./minus.png');
  background-repeat: no-repeat;
  background-position: center;
}

.leaflet-pane {
  z-index: auto;

  &.leaflet-popup-pane {
    z-index: 700;
  }
}

.leaflet-top,
.leaflet-bottom {
  z-index: 8 !important;
}


@media (max-width: 768px) {
  .leaflet-touch .leaflet-bar a {
    width: 44px;
    height: 44px;
  }
  .leaflet-top {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .leaflet-control-zoom-in::before {
    left: 13.2px;
    top: 2px;
  }

  .leaflet-control-zoom-out::before {
    left: 13.2px;
    top: 2px;
  }

  .leaflet-right .leaflet-control {
    margin-right: 7px;
  }
}
