.ticketDetails {
  display: grid;
  grid-template-columns: minmax(auto, auto) 1fr 1fr max-content;
  align-items: center;
  gap: 6px;
  margin: 6px 0;

    &:last-child {
      margin-bottom: 20px;
    }

    &:first-child {
      margin-top: 20px;
    }

    .price {
      text-align: center;
      text-decoration: line-through;
      opacity: 0.25;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
    }
    .priceWithoutDiscount {
      min-width: max-content;
      text-align: center;
      color: var(--systemBlack);
      letter-spacing: -0.26px;
      font-weight: bold;
      font-size: 22px;
      line-height: 110%;
      letter-spacing: -0.26px;
    }

    .priceWithDiscount {
      min-width: max-content;
      text-align: center;
      color: var(--clubPrimary);
      letter-spacing: -0.26px;
      font-weight: bold;
      font-size: 22px;
      line-height: 110%;
      letter-spacing: -0.26px;
    }
}

.removeLoader {
  display: flex;
}

.ticketDetails.isMobile {
  grid-template-columns: minmax(auto, 0.7fr) auto auto max-content;

  .price,
  .priceWithoutDiscount,
  .priceWithDiscount {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
}

@media (max-width: 792px) {
  .ticketDetails.isMobile {
    grid-template-columns: auto auto auto max-content;
    .price,
    .priceWithoutDiscount,
    .priceWithDiscount {
      text-align: end;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 390px) {
  .ticketDetails.isMobile {
    gap: 4px;
    .price,
    .priceWithoutDiscount,
    .priceWithDiscount {
      font-size: 11px;
    }
  }
}