.logoContainer {
  display: flex;
  align-items: center
}

.logoImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: width ease-in 0.4s, height ease-in 0.4s;
}

.logoHide {
  height: 0 !important;
  width: 0 !important;
}

@media only screen and (max-width: 520px) {
  
}
