.layout {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .main {
    flex: 1 1 auto;
    position: relative;
  }
}

@media screen and (max-width: 1056px) {
  .layout {
    margin: 0 16px;
  }
}
