.phoneNumber {
  p {
    margin: 0;
  }
  h5 {
    margin: 15px 0;
  }
}

@media (max-width: 600px) {
  p, h5 {
    font-size: 14px;
  }
}