.btn {
  min-width: 28px;
  height: 28px;
  display: block;
  border: none;
  border-radius: 50%;
  opacity: 0.8;
  padding: 0;
  background-color: var(--systemBlack25);
  transition: background 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: var(--systemBlack10);
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 4px;
  flex-grow: 1;
}

.btn.systemBlack {
  background-color: var(--systemBlack25);
  transition: background 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: var(--systemBlack10);
  }
}

.btn.systemWhite {
  background-color: var(--systemWhite);
  transition: background 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: var(--systemBlack25);
  }
}

.btn.big {
  min-width: 28px;
  height: 28px;
}

.btn.medium {
  min-width: 21px;
  height: 21px;

  svg {
    width: 12px;
    height: 12px;
  }
}

.btn.small {
  min-width: 18px;
  height: 18px;

  svg {
    width: 8px;
    height: 8px;
  }
}
