.ordersWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  overflow: hidden;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;

  .bottomWrap {
    margin-top: auto;
  }
}
.orderFactoryWrapper {
  overflow: auto;
  padding-right: 12px;
  position: relative;
}

.addTickets {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--systemWhite);
  opacity: 0.9;
  border: 1px solid var(--systemBlack25);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: var(--systemBlack50);
  padding: 12px 0;
  width: 100%;

  svg {
    margin: 0 10px;
    fill: var(--clubPrimary);
  }
}