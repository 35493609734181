.customRadio {
  margin: 0 12px;
  &>input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &>span {
    width: 100%;
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-left: -2px;
    margin-top: -2px;
  }

  &>span::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid rgba(50, 50, 50, 0.25);
    border-radius: 50%;
    margin-right: 9px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }

  &>input:checked+span::before {
    background-color: var(--clubPrimary);
    border-color: var(--clubPrimary);
    background-image: url('./../../../../assets/icons/radio_button.svg');
  }
}