.coupons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;

  height: fit-content;
  margin-bottom: 16px;
  padding: 10px 12px;

  border: 1px solid var(--systemBlack25);
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
}

.coupon {
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;

  width: 100%;
  list-style: none;

  color: var(--systemBlack);
  font-size: 12px;

  border-radius: 8px;
  background-color: var(--systemBlack05);
}

.discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label {
  color: var(--systemBlack);
  margin: 0;
}

.value {
  color: var(--clubPrimary);
  margin: 0;
}

.code {
  color: var(--systemBlack);
  font-weight: 700;
  font-size: 12px;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4px 8px;

  background-color: var(--systemWhite);
  border-radius: 16px;
  color: var(--systemBlack50);
  font-size: 12px;
  font-weight: 500;
}

.divider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--systemBlack25);
}

.loader {
  height: auto;
}

.textButton {
  outline: none;
  border: none;
  background: transparent;
  padding: 4px 0;

  cursor: pointer;
  color: var(--clubPrimary);
  font-weight: 500;
  font-size: 16px;
  &:disabled {
    color: var(--systemBlack50);
  }
}
