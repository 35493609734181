.menuIcon {
  width: 18px;
  height: 18px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menuIcon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--systemBlack);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menuIcon.open span {
  background: var(--systemWhite);
}

.menuIcon span:nth-child(1) {
  top: 3px;
}

.menuIcon span:nth-child(2),
.menuIcon span:nth-child(3) {
  top: 9px;
}

.menuIcon span:nth-child(4) {
  top: 15px;
}

.menuIcon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.menuIcon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menuIcon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menuIcon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.desktop {
  display: inherit;
}
