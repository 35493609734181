.warnBanner {
  margin: 8px 0 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  background: var(--systemRed);
  border-radius: 12px;
  color: var(--systemWhite);
  cursor: pointer;

  .warnText {
    display: flex;
    max-width: 586px;
    font-weight: 700;
    letter-spacing: -0.26px;
    margin: 20px;

    h3 {
      font-size: 22px;
      margin: 0;
    }
  }

  .warnButton {
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-self: flex-end;
    margin: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
  }
}

@media screen and (min-width: 761px) and (max-height: 1080px), (max-width: 720px) {
  .warnBanner {
    .warnText {
      max-width: 65%;
      h3 {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 360px) {
  .warnBanner {
    .warnButton {
      font-size: 11px;
    }
  }
}
