.headerBtn {
  color: var(--clubPrimary);
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.backBtn {
  color: var(--systemBlack);
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      fill: var(--clubPrimary)
    }
  }
}