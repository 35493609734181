.RangeSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .rangeInput {
    width: 100%;
    margin: 17px auto;
  }

  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
  }
  
  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    // background: var(--systemOrange);
    border-radius: 8px;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--systemOrange);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    // background: #FF9500;
    border-radius: 8px;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--systemOrange);
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: var(--systemOrange);
    border-radius: 16px;
  }
  
  input[type=range]::-ms-fill-upper {
    background: #EBEDEE;
    border-radius: 16px;
  }

  input[type=range]::-ms-thumb {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--systemOrange);
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: var(--systemOrange);
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #EBEDEE;
  }

  .sliderBtn {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 14px 16px;
    color: var(--systemWhite);
    background: var(--systemOrange);
    border-radius: 10px;
    transition: 0.5s;

    &:hover {
      box-shadow: 0px 4px 16px  var(--systemOrange);
      transition: 0.5s;
    }
  }
}