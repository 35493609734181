.IconWithCounter {
  position: relative;
  display: flex;
  .counter {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 16px;
    min-width: 16px;
    border-radius: 50%;
    background: var(--systemGreen);
    color: var(--systemWhite);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -40%);
    span {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 120%;
    }
  }
}