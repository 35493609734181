.card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 12px 16px;

  h5 {
    margin: 0;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
  }

  img {
    width: 100px;
    height: 32px;
    margin-right: 11px;
  }
}
