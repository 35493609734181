.center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredText {
  text-align: center;
}

.dispencedList {
  :not(:last-child){
    margin-bottom: 30px;
  }
}

.skeleton__avatar:empty,
.skeleton__span:empty {
  background-color: var(--systemBlack25);
  animation: blink 3s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
.skeleton__avatar:empty {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.skeleton__span:empty {
  opacity: 1 !important;
  display: block !important;
  height: 1em;
  width: 100px;
}