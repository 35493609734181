.numberedList {
  list-style: auto;
  padding-left: 18px;
}

@media (max-width: 880px) {
  .slide {
    padding-top: 32px;
    padding-bottom: 12px;
  }

  .title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    color: var(--clubNavigation1);
    margin-top: 0;
    margin-bottom: 16px;
  }

  .text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--clubNavigation1);
  }

  .listItem {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--clubNavigation1);
    margin-bottom: 6px;
  }
}