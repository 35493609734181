.NoEventsBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 16px;

  svg {
    width: 90%;
  }

  .bannerInfo {
    margin: 12px;
    color: var(--clubNavigation1);

    h2, p {
      opacity: 0.5;
    }
  }
}

@media (max-width: 720px) {
  .NoEventsBanner {
    flex-direction: column;
  }
  .bannerInfo {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 420px) {
  .NoEventsBanner {
    .bannerInfo {
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}