.web_webBody1Bold {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 16;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webBody1Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 16;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webBody1Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 16;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webBody2Demibold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 14;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webBody2Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 14;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webBody2Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 14;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH1Bold {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 36;
    line-height: 120%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH1Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 36;
    line-height: 120%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH1Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 36;
    line-height: 120%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH2Bold {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 28;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
}
.web_webH2Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 28;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
}
.web_webH2Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 28;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
}
.web_webH3Bold {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 22;
    line-height: 110%;
    letter-spacing: -0.26px;
    text-align: left;
    align-items: start;
}
.web_webH3Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 22;
    line-height: 110%;
    letter-spacing: -0.26px;
    text-align: left;
    align-items: start;
}
.web_webH3Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 22;
    line-height: 110%;
    letter-spacing: -0.26px;
    text-align: left;
    align-items: start;
}
.web_webH4Bold {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 18;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH4Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 18;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webH4Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 18;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webHero {
    font-family: TTHoves-Bold;
    font-weight: 700;
    font-size: 48;
    line-height: 120%;
    letter-spacing: normal;
    text-align: left;
    align-items: start;
}
.web_webSmallText1CapsDemibold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 12;
    line-height: 110%;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
}
.web_webSmallText1DemiBold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 12;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText1Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 12;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText1Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 12;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText2CapsDemiBold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 10;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: center;
    align-items: start;
}
.web_webSmallText2CapsDemiBold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 10;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText2DemiBold {
    font-family: TTHoves-DemiBold;
    font-weight: 400;
    font-size: 10;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText2Medium {
    font-family: TTHoves-Medium;
    font-weight: 500;
    font-size: 10;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}
.web_webSmallText2Regular {
    font-family: TTHoves-Regular;
    font-weight: 400;
    font-size: 10;
    line-height: 120%;
    letter-spacing: 0.11px;
    text-align: left;
    align-items: start;
}