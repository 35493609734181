.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  &_visible {
    visibility: visible;
    opacity: 1;
  }
}

.modal {
  position: relative;
  background-color: var(--systemWhite);
  border-radius: 10px;
  width: auto;
  margin: auto;
  margin-top: 150px;
  animation: outAnimation 310ms ease-in;
  overflow: hidden;
  &_visible {
    animation: inAnimation 310ms ease-in;
  }
}

.loyaltyModal {
  max-width: 764px;
}

.modalBody {
  padding: 42px;
  padding-bottom: 24px !important;
}

@media (max-width: 840px) {
  .modal {
    margin: 24px 16px 24px;
  }
}


@media (max-width: 840px) {
  .loyaltyModal {
    max-width: 764px !important;
    margin: 24px 16px 24px;
  }
}

@media (max-height: 742px) {
  .loyaltyModal {
    top: 70px;
  }
}

@media (max-height: 630px) {
  .loyaltyModal {
    top: 150px;
  }
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes outAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
