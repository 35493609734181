.Multistep {
  padding: 18px;
  form{
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    min-height: 250px;

    p {
      a{
        color: var(--clubPrimary);
        cursor: pointer;
      }
    }
  }

  button {
    align-self: center;
  }
}

@media (max-width:  968px) {
  .Multistep {
    form {
      min-height: 150px;
      height: auto;
    }
  }
}

@media (max-width:  420px) {
  .Multistep {
    form {
      width: 265px;
    }
  }
}

@media only screen and (max-width: 768px) and (orientation:landscape){
  .Multistep {
    form {
      min-height: 100px;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .Multistep {
    form {
      font-size: 14px;
      
      div {
        h4, div input {
          font-size: 14px !important;
        }
      }
    }
  }
}