.Input {
  width: 100%;
  color: #323232;

  .inputTitle {
    margin: 0;
  }

  .inputWrap {
    width: 100%;
    display: flex;
    border-bottom: 2px solid rgba(50, 50, 50, 0.5);
    input {
      flex: 1;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 110%;
      padding: 9px 0;
      background: transparent;
      outline: none;
    }

     &.focus {
      border-bottom: 2px solid var(--clubPrimary);
    }

    svg {
      cursor: pointer;
    }
  }
  .labelWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 110%;
    margin: 7px 0;

    .textButton {
      text-align: end;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;

      &.active span {
        color: var(--clubPrimary);
      }

      &.disabled span {
        cursor: default;
      }
    }
  }

  label.errorLabel {
    opacity: 1;
    color: var(--systemRed);
  }

  label {
    font-style: normal;
    font-weight: normal;
    opacity: 0.5;
  }
}

@media (max-width: 920px) {
  .Input {
    .labelWrap {
      font-size: 12px;
    }
  }
}

@media (max-width: 600px) {
  .Input {
    .inputWrap input {
      font-size: 14px;
    }
  }
}
