@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  flex: 0 1 auto;
  min-height: 88px;

  .logoContainer {
    display: flex;
    flex: 1;
    gap: 8px;
    align-items: center;
    z-index: 15;
  }

  .additionalLogo {
    width: 103px;
    height: 22px;
  }

  .additionalLogoText {
    color: var(--systemBlack);
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
  }

  .additionalLogo-shinnik {
    width: 240px;
    height: 35px;

  }
  @media (max-width: 840px) {
    .additionalLogoText {
      font-size: 18px;
    }

    .additionalLogo-shinnik {
      width: 207px;
      height: 22px;
    }
  }

  .additionalLogoAlt {
    filter: invert(100%);
  }

  .additionalLogoTextAlt {
    color: var(--systemWhite);
  }

  .langsContainer {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .langCode {
    cursor: pointer;
    font-weight: 500;
  }

  .activeLangCode {
    color: var(--clubPrimaryLight);
  }

  .logo {
    height: 46px;
    width: 46px;
  }
}

