.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  h1 {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 768px) and (orientation:landscape){
  .wrapper {
    position: relative;
  }
}

@media screen and (max-width: 720px) {
  .wrapper {
    position: relative;
    
    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 380px) {
  .wrapper {
    h3 {
      font-size: 16px;
    }
  }
}
