.footer {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "copy links apps";
  gap: 6px;
  align-items: center;
  background: none;
  box-shadow: none;
  padding: 30px 0;
  white-space: nowrap;

  .copyContainer {
    color: var(--clubNavigation1);
    text-align: start;
    grid-area: copy;
  }

  .copyright {
    margin: 4px 0;
    text-wrap: balance;
  }

  .requisites {
    margin: 4px 0;
    font-size: 12px;
    text-wrap: balance;
    color: var(--systemBlack25);
  }

 .linkAppContainer {
    grid-area: apps;
  }

  .linkContainer {
    text-align: end;
    justify-content: space-around;
    grid-area: links;
  }

  ul:last-child {
    justify-content: flex-end;
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    align-items: center;
    padding: 0;

    svg {
      height: 37px;
      width: auto;
    }

    li:not(:first-child) {
      margin-left: 6px;
    }

    a {
      cursor: pointer;
    }
  }

  a:focus, ul.linkContainer {
    color: var(--clubPrimary);
  }
}

@media only screen and (max-width: 1024px) {
  .footer {
    font-size: 13px;
    ul {
      svg {
      height: 31px;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .footer {
    grid-template-areas:
    "apps apps apps"
    "copy copy links";

    ul:last-child.linkAppContainer {
      justify-content: flex-start;
    }

    .linkAppContainer {
      justify-content: start;
    }
  }
}

@media only screen and (max-width: 580px) {
  .footer {
    grid-template-areas:
    "apps apps apps"
    "links links links"
    "copy copy copy";

    ul.linkContainer {
      justify-content: start;
      flex-wrap: wrap;
      li {
        text-align: start;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
