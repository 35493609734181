.ModalHeader {
  background-color: var(--systemRed);
}

.ModalBody {
  max-width: 764px;
}

.ModalHeaderBody {
  min-height: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  color: var(--systemWhite);
}

.ModalHeaderTitle {
  align-self: flex-end;
  margin: 0;
}

.ModalBodyTitle {
  margin: 0;
}

.ModalCardsSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 42px;
  gap: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ModalCard {
  max-width: 270px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin-right: 42px;
  margin-bottom: 24px;
}

.ModalCardHeader {
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.ModalCardImg {
  max-width: 52px;
  border: none;
}

.ModalCardTitle {
  max-width: 200px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--systemRed);
  align-self: center;
  margin: 0;
}

.ModalCardDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--systemBlack);
  margin: 0;
}

.ModalSmallCard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 0%;
  gap: 14px;
}

.ModalSmallCardImg {
  width: 28px;
}

.ModalSmallCardDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--systemBlack);
  margin: 0;
  min-width: 300px;
}

.ModalText {
  flex: 1 1 0%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--systemBlack50);
  margin: 0;
  min-width: 300px;
}

@media (max-width: 740px){
  .ModalCardTitle, .ModalCard, .ModalText, .ModalSmallCardDescription, .ModalCardDescription {
    max-width: 600px;
  }
}