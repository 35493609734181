.successWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;

  svg {
    width: 80%;
    height: auto;
  }

  h3 {
    margin: 14px;
  }

  p {
    margin-top: 0;
  }
}

@media (max-width: 920px) {
  .successWrap {
    min-width: 380px;

    h3 {
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .successWrap {
    min-width: 265px;
  }
}