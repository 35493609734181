.loyalty {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--systemOrange);
  font-size: 18px;
  font-weight: 800;
  span {
    font-size: 31px;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-weight: 700;
  }
}
