.CountdownTimer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
  text-transform: uppercase;
  color: var(--systemWhite);
  min-width: fit-content;
  height: fit-content;
  background: var(--systemRed);
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  margin: 0 6px;
    
    span {
      margin-right: 3px;
    }

    p {
      margin: 0px;
      display: inline-block;
    }

  .timerIcon {
      display: flex;
      align-items: center;
      margin-right: 3px;
  }

  .timerLabel {
    height: fit-content;
  }

  .timerWrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
  }
}

@media (max-width: 380px) {
  .CountdownTimer {
    font-size: 11px;
  }
}