.separatedList {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
}

.separatedList > div, section {
  border-bottom: 2px dashed rgba(50, 50, 50, 0.2);
}

.separatedList > div:last-child, section:last-child {
  border-bottom: none;
}