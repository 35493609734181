.skeleton {
  background-color: var(--systemBlack25);
  animation: blink 3s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}

.skeleton__avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.skeleton__span {
  opacity: 1 !important;
  display: block !important;
  height: 1em;
  width: 100px;
  margin-bottom: 2px;
}

.skeleton__line {
  opacity: 1 !important;
  display: block !important;
  height: 1em;
  margin-bottom: 2px;
}