.orderDetails {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
  border-left: 2px solid rgba(50, 50, 50, 0.1);
  overflow: auto;

  h3 {
    margin-top: 0;
  }

}
